var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "helpCenter" },
    [
      _c("v-header"),
      _c(
        "el-container",
        { staticClass: "main" },
        [
          _c(
            "el-header",
            _vm._l(_vm.tabType, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  class: [_vm.tabId == index ? "cur" : "", "tabs"],
                  on: {
                    click: function($event) {
                      return _vm.tabChange(index)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(item.text) + " ")]
              )
            }),
            0
          ),
          _c(
            "el-container",
            { staticClass: "qa-container" },
            [
              _c("el-aside", { attrs: { width: "200px" } }, [
                _c(
                  "ul",
                  _vm._l(_vm.tabType2, function(item, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        class: { activeTag: item.isSelect },
                        on: {
                          click: function($event) {
                            return _vm.handleSelect(item, index)
                          }
                        }
                      },
                      [
                        _c("span", [_vm._v(_vm._s(item.text))]),
                        _c("i", { staticClass: "el-icon-arrow-right" })
                      ]
                    )
                  }),
                  0
                )
              ]),
              _c(
                "el-main",
                { staticClass: "container-bar" },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loadmore",
                          rawName: "v-loadmore",
                          value: _vm.loadMore,
                          expression: "loadMore"
                        }
                      ],
                      staticClass: "wrapper "
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "body-wrapper" },
                        [
                          _c(
                            "el-collapse",
                            {
                              attrs: { accordion: "" },
                              on: { change: _vm.handleChange },
                              model: {
                                value: _vm.itemsId,
                                callback: function($$v) {
                                  _vm.itemsId = $$v
                                },
                                expression: "itemsId"
                              }
                            },
                            _vm._l(_vm.question, function(item, index) {
                              return _c(
                                "el-collapse-item",
                                { key: index, attrs: { name: item.id } },
                                [
                                  _c("template", { slot: "title" }, [
                                    _c("i", {
                                      staticClass:
                                        "header-icon el-icon-caret-right"
                                    }),
                                    _vm._v(_vm._s(item.title) + " ")
                                  ]),
                                  _c("div", {
                                    domProps: { innerHTML: _vm._s(_vm.content) }
                                  })
                                ],
                                2
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c("el-backtop", {
                    attrs: { target: ".container-bar", bottom: 50 }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }